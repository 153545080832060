import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from "dayjs";
import { GET_TRANSFER } from "@/graphql/payment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { imageUrlApi, WEBSITE } from "@/config";
import bigDecimal from "js-big-decimal";
export default {
  name: "PaymentDetail",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "โอนเงินไปจีน",
      route: "/payments"
    }, {
      title: "\u0E42\u0E2D\u0E19\u0E40\u0E07\u0E34\u0E19\u0E44\u0E1B\u0E08\u0E35\u0E19 #".concat(this.id)
    }]);
  },
  created: function created() {
    document.title = "\u0E42\u0E2D\u0E19\u0E40\u0E07\u0E34\u0E19\u0E44\u0E1B\u0E08\u0E35\u0E19 | ".concat(WEBSITE);
  },
  data: function data() {
    var id = this.$route.params.id;
    return {
      id: Number(id),
      guarantee: false,
      valid: false
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
        case -1:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        default:
          return "primary";
      }
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var yuan = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var currency = format ? yuan ? "¥ " : "฿ " : "";

      if (format) {
        if (val >= 0) return "".concat(currency).concat(Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
        return "-".concat(currency).concat(Number(bigDecimal.round(Math.abs(val), decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
      }

      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    payment: {
      query: GET_TRANSFER,
      variables: function variables() {
        return {
          id: this.id
        };
      }
    }
  }
};